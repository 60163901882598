(function($) {

  $('.accordion__header').click(function() {
    // handle the accordion header being clicked
    var $currItem = $(this).closest('.accordion__item');
    $currItem.toggleClass('selected');

    // set the value of the toggle (for screen readers only / not visible on the website)
    var $currToggle = $currItem.find('.accordion__toggle > span');
    var $currVal = $currToggle.html();
    console.log($currVal);
    var $newVal = ($currVal === 'open') ? 'sluit' : 'open';
    console.log($newVal);
    $currToggle.text($newVal);
  });

})(jQuery);
